import { Injectable, SecurityContext } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ISiteProduct } from "src/models/site-product";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { IConsumer } from "src/models/consumer";
import { ICustomerPass, ICustomerPassWithSiteProducts } from "src/models/customer-pass";
import { DomSanitizer } from "@angular/platform-browser";
import { IPassPaymentAdditionalInfo, WNPaymentResponse } from "src/models/worldnet-models";
import { IPromo } from "src/models/promo";
import { CustomerInformation, CustomerSiteInfo, ICustomer, ICustomerDashboard } from "src/models/customer";
import { Router } from "@angular/router";
import { ConsumerLogin } from "src/models/consumer-login";
import { TextReceiptInformation } from "src/models/text-receipt-information";
import { ReceiptComponent } from "src/app/receipt/receipt.component";
import { AppSettingsService } from "src/app/app-settings/app-settings.service";
import { SiteInformation, ModalSiteInfo } from "src/models/site-information";
import { IPostGiftPassPurchase, IPostPassPurchase } from "src/models/api";
import { IPassPurchaseDetails } from "src/models/purchase-details";
import { Addresses } from "src/models/addresses";
import { AddressType } from "src/app/enumurations/address-type.enum";
import { ConsumerDetails } from "src/models/consumer-details";
import { CustomerDetails } from "src/models/customer-details";
import { Payment } from "src/models/payment";
import { CreditCard } from "src/models/credit-card";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private readonly urlBase: string;
  readonly mapsApiKey: string;
  public siteId: string;
  public customerId: Observable<string>;
  private readonly recieptURL: string;

  private userSubject: BehaviorSubject<ConsumerLogin | null>;
  public user$: Observable<ConsumerLogin | null>;

  apiLoaded: Observable<boolean>;

  public customerInfoSubject = new BehaviorSubject<CustomerInformation>(null);
  public customer$ = this.customerInfoSubject.asObservable();
  public textReceipt = new BehaviorSubject<TextReceiptInformation>(null);
  public receipt$ = this.textReceipt.asObservable();
  public consumerCustomerListSubject = new BehaviorSubject<CustomerInformation[]>(null);
  public consumerCustomerList$ = this.consumerCustomerListSubject
  public modalSiteInfo = new BehaviorSubject<ModalSiteInfo[]>(null);
  public modalSites$ = this.modalSiteInfo.asObservable();

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private appSettingsService: AppSettingsService,)
    {
    this.urlBase =`${this.appSettingsService.apiUrl}/${this.appSettingsService.apiVersion}`;
    this.mapsApiKey =`${this.appSettingsService.googleMapsKey}`;

    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user$ = this.userSubject.asObservable();
  }

  // public get apiUrl() : string { return this.appSettingsService.apiUrl }
  public get consumerValue() {
    return this.userSubject.value;
  }

  login(phoneNumber: string, password: string, customerId: string){
    const body = {
      EmailOrPhoneNumber: phoneNumber,
      Password: password,
    };
    return this.http.post<ConsumerLogin>(`${this.urlBase}/Token/login/${customerId}/consumer`, body)
    .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
    }));
  }

  reset(phoneNumber: string, password: string, confirmPassword: string, customerId: string){
    const body = {
      EmailOrPhoneNumber: phoneNumber,
      Password: password,
      ConfirmPassword: confirmPassword
    };
    return this.http.post(`${this.urlBase}/Token/passwordreset/${customerId}/consumer`, body);
  }

  signUp(phoneNumber: string, customerId: string){
    return this.http.get<IConsumer>(`${this.urlBase}/Consumer/${phoneNumber}/${customerId}/accountcheck`);
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['']);
  }

  getConsumerDetails(customerId: string, phoneNumber: string) {
    return this.http.get<ConsumerDetails>(`${this.urlBase}/Dashboard/customer/${customerId}/consumer/${phoneNumber}`);
  }

  getCustomerDetails(customerId: string, signedURL: string) {
    return this.http.get<CustomerDetails>(`${this.urlBase}/Dashboard/customerdetails/${customerId}/${signedURL}`);
  }

  consumerCustomers(phoneNumber: string){
    const body = {
      MobileNumber: phoneNumber,
      email: "",
    };
    return this.http.post<CustomerInformation[]>(`${this.urlBase}/customer/customerlist`, body).pipe(
      map(customers =>{
        this.consumerCustomerListSubject.next(customers);
        return customers;
      })
    );
  }

  getPurchaseHistory(consumerId: string, token: string, consumerPassId?: string | null){
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    var body = {}
    if (consumerPassId != null)
    {
      body = {passid: consumerPassId};
    }
    return this.http.get<Payment[]>(`${this.urlBase}/Payment/consumer/${consumerId}/history/`, {
      headers:new HttpHeaders(headerDict),
      params: body,
    })
  }

  updateConsumer(id: string, consumer: Partial<IConsumer>) {
    return this.http.put<IConsumer>(`${this.urlBase}/Consumer/${id}`, consumer)
  }

  deleteConsumer(id: string, token: string){
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
        headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<IConsumer>(`${this.urlBase}/Consumer/${id}/deleteConsumer`, requestOptions);
  }

  consumerDeleteRequest(consumerId: string){
    var body = {};
    return this.http.post(`${this.urlBase}/consumer/requestingDeletion/${consumerId}`, body);
  }

  getSiteProducts(siteId: string, getImages?: boolean): Observable<ISiteProduct[]> {
    let httpParams = new HttpParams();
    if (getImages != null) {
      httpParams = httpParams.append("getImages", getImages.toString());
    }

    return this.http
      .get<ISiteProduct[]>(`${this.urlBase}/sites/${siteId}/products`, {
        params: httpParams,
      })
      .pipe(
        map((response) => {
          response.sort((a, b) => b.price - a.price);
          for (let i = response.length - 1; i >= 0; i--) {
            let siteProduct = response[i];
            siteProduct.modified = new Date(siteProduct.modified);
            siteProduct.timestamp = new Date(siteProduct.timestamp);
            siteProduct.productDescriptions.sort(
              (a, b) => a.descriptionOrder - b.descriptionOrder
            );
          }
          return response;
        })
      );
  }

  getEnvironmentCustomerId() {
    return this.customerId;
  }
  private calculateTotalPassCost(pass: ICustomerPass): number {
    return pass.passCost + (pass.passCostCents / 100);
  }

  getSiteCustomerPasses(
    siteId: string,
    kioskVisible?: boolean,
    mobileVisible?: boolean
  ): Observable<ICustomerPass[]> {
    let httpParams = new HttpParams();
    if (kioskVisible != null) {
      httpParams = httpParams.append("kioskVisible", kioskVisible.toString());
    }
    if (mobileVisible != null) {
      httpParams = httpParams.append("mobileVisible", mobileVisible.toString());
    }

    return this.http
      .get<ICustomerPass[]>(
        `${this.urlBase}/customerpass/${siteId}/site`,
        { params: httpParams }
      )
      .pipe(
        map((response) => {
          response.forEach(pass => {
            pass.passCost = this.calculateTotalPassCost(pass);
          });
          response.sort((a, b) => b.passCost - a.passCost);
          for (let i = response.length - 1; i >= 0; i--) {
            let customerPass = response[i];
            customerPass.modified = new Date(customerPass.modified);
            customerPass.timestamp = new Date(customerPass.timestamp);
            for (let j = customerPass.sites.length - 1; j >= 0; j--) {
              let site = customerPass.sites[j];
              site.modified = new Date(site.modified);
              site.timestamp = new Date(site.timestamp);
            }
            for (let j = customerPass.products.length - 1; j >= 0; j--) {
              let product = customerPass.products[j];
              product.modified = new Date(product.modified);
              product.timestamp = new Date(product.timestamp);
            }
            for (let j = customerPass.limits.length - 1; j >= 0; j--) {
              let limit = customerPass.limits[j];
              for (let k = limit.timeRanges.length - 1; k >= 0; k--) {
                let timeRange = limit.timeRanges[k];
                timeRange.start = new Date(timeRange.start);
                timeRange.end = new Date(timeRange.end);
              }
            }
          }
          return response;
        }));
  }

  getCustomerPass(id: string): Observable<ICustomerPassWithSiteProducts> {
    return this.http.get<ICustomerPassWithSiteProducts>(`${this.urlBase}/customerpass/${id}`);
  }

  getSiteImage(): Observable<any> {
    return this.http.get(`${this.urlBase}/sites/${this.siteId}/image`);
  }

  getDescriptionImage(pictureKey: string): Observable<Blob> {
    return this.http.get(`${this.urlBase}/sites/descriptions/${pictureKey}`, {
      responseType: "blob",
    });
  }

  getCustomerInformation(customerId: string): Observable<CustomerInformation> {
    return this.http.get<CustomerInformation>(`${this.urlBase}/UniversalPassSales/customerinfo/${customerId}`);
  }

  getCustomerSites(customerId: string): Observable<SiteInformation[]>{
    return this.http.get<SiteInformation[]>(`${this.urlBase}/sites/${customerId}/all`);
  }

  getCustomerSite(siteId: string): Observable<SiteInformation>{
    return this.http.get<SiteInformation>(`${this.urlBase}/sites/${siteId}`);
  }

  setTextReceiptInformation(id: string) {
    return this.http.get<TextReceiptInformation>(`${this.urlBase}/Payment/${id}/textreceiptinfo`);
  }


  getModalSites(latitude: number, longitude: number): Observable<ModalSiteInfo[]> {
    return this.http.get<ModalSiteInfo[]>(`${this.urlBase}/Dashboard/consumer/${latitude}/${longitude}`);
  }

  /** Creates and returns a partial consumer account. If a consumer account already exists, just returns the account. */
  safeCreatePartialAccount(
    customerId: string,
    phoneNumber: string,
    customerPassId: string
  ): Observable<IConsumer> {
    const body = {
      mobileNumber: phoneNumber,
      customerPassId: customerPassId,
    };
    return this.http.post<IConsumer>(
      `${this.urlBase}/consumer/${customerId}/createpartialweb`,
      body
    );
  }

  createAccount(customerId: string, consumer: any)
  {
    const address: Addresses = {
      street: consumer.street,
      city: consumer.city,
      stateOrProvince: consumer.stateOrProvince,
      zip: consumer.zip,
      country: consumer.country,
      addressType: AddressType.current
    }
    var addresses = []
    addresses.push(address)
    const body = {
      email: consumer.email,
      firstName: consumer.firstName,
      lastName: consumer.lastName,
      mobileNumber: consumer.mobileNumber,
      password: consumer.password,
      confirmPassword: consumer.confirmPassword,
      addresses: addresses,
    }
    return this.http.post<IConsumer>(`${this.urlBase}/consumer/${customerId}/create`, body);
  }

  getConsumer(id: string) {
    return this.http.get<IConsumer>(`${this.urlBase}/consumer/${id}/getConsumer`);
  }

  cancelPass(id: string, token: string){
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
    };
    return this.http.put(`${this.urlBase}/ConsumerPass/${id}/cancel`, requestOptions);
  }

  // getCustomer(id: string) {
  //   return this.http.get<ICustomer>(`${this.urlBase}/customer/${id}/getCustomer`);
  // }

  // getConsumerPasses(id: string) {
  //   const body = {
  //     active: true,
  //     suspended: false,
  //     canceled: false,
  //     expired: false,
      
  //   };
  //   return this.http.post<IConsumerPasses>(`${this.urlBase}/consumer/${id}/passes`, body);
  // }

  getHostedPaymentPage(
    receiptUrl: string,
    customerId: string,
    siteId: string,
    consumerId: string,
    price: number,
    additional: IPassPaymentAdditionalInfo
  ) {
    const isTrusted = true;
    const body = {
      additionalInfo: additional,
      consumerId: consumerId,
      price: price,
      receiptUrl: `${receiptUrl}/#/customer/${customerId}/pass-receipt/${siteId}`,
    };

    const url = `${this.urlBase}/customer/hpprequest/${customerId}`;

    return this.fetchStaticHTML(url, isTrusted, "POST", body);
  }

  validatePromo(
    code: number,
    phoneNumber: string,
    customerId: string
  ): Observable<IPromo> {
    const isTrusted = true;
    const url = `${this.urlBase}/promos/ValidatePromo`;
    const body = {
      customerId: customerId,
      code: code,
      phoneNumber: phoneNumber,
    };
    return this.http.post<IPromo>(url, body);
  }

  redeemPromo(code: number, phoneNumber: string, customerId: string): any {
    const isTrusted = true;
    const url = `${this.urlBase}/promos/RedeemPromo`;
    const body = {
      customerId: customerId,
      code: code,
      phoneNumber: phoneNumber,
    };
    return this.http.post<IPromo>(url, body, { responseType: "json" });
  }

  postPassPurchase(
    siteId: string,
    receipt: WNPaymentResponse,
    consumerId: string,
    customerPassId: string,
    textURL: string, 
    thankYouURL: string
  ) {
    const body: IPostPassPurchase = {
      customerPassId: customerPassId,
      consumerId: consumerId,
      siteId: siteId,
      wnOrderId: receipt.ORDERID,
      wnCardNumber: receipt.CARDNUMBER,
      wnCardType: receipt.CARDTYPE,
      wnUniqueRef: receipt.UNIQUEREF,
      wnResponseCode: receipt.RESPONSECODE,
      wnResponseText: receipt.RESPONSETEXT,
      wnApprovalCode: receipt.APPROVALCODE,
      amount: receipt.AMOUNT,
      cardExpiry: receipt.CARDEXPIRY,
      cardReference: receipt.CARDREFERENCE,
      textURL: textURL,
      thankYouURL: thankYouURL,
      tax: receipt.TAX,
      surcharge: receipt.SURCHARGE
    };
    return this.http.post(`${this.urlBase}/Payment/webpasspurchase`, body).subscribe(
      (data) => console.log(data)
    );
  }

  postGiftPassPurchase(
    siteId: string,
    receipt: WNPaymentResponse,
    details: IPassPurchaseDetails,
    consumerId: string,
    customerPassId: string,
    textURL: string
  ) {
    const body: IPostGiftPassPurchase = {
      amount: receipt.AMOUNT,
      consumerId: consumerId,
      customerPassId: customerPassId,
      siteId: siteId,
      wnApprovalCode: receipt.APPROVALCODE,
      wnCardNumber: receipt.CARDNUMBER,
      wnCardType: receipt.CARDTYPE,
      wnOrderId: receipt.ORDERID,
      wnResponseCode: receipt.RESPONSECODE,
      wnResponseText: receipt.RESPONSETEXT,
      wnUniqueRef: receipt.UNIQUEREF,
      cardExpiry: receipt.CARDEXPIRY,
      cardReference: receipt.CARDREFERENCE,
      textURL: textURL,
      toPhone: details.recipientPhoneNumber,
      giftEmail: details.emailAddress,
      giftMonths: details.monthsDuration,
      giftPurchaserMobile: details.phoneNumber,
      activationDate: details.activationDate,
      fromName: details.fromName,
      toName: details.toName
    };
    return this.http.post(`${this.urlBase}/payment/webgiftpasspurchase`, body).subscribe(
      (data) => console.log(data)
    );
  }

  getCCSummary(consumerId: string, token: string) {
    var headers = new HttpHeaders();
    headers.append('authorization', 'Bearer ' + token);

    const headerDict = {
        'authorization': 'Bearer ' + token
    }
    
    const requestOptions = {
        headers: new HttpHeaders(headerDict), 
    };
    return this.http.get<CreditCard>(`${this.urlBase}/Consumer/${consumerId}/ccsummary`, requestOptions)
  }

  fetchStaticHTML(
    url: string,
    isTrusted: boolean,
    method: "GET" | "POST" = "GET",
    body?: any
  ): Observable<string> {
    if (method === "POST") {
      return this.http
        .post(url, body, { responseType: "text" })
        .pipe(map((response) => this.mapStaticHtml(response, isTrusted)));
    } else {
      return this.http
        .get(url, { responseType: "text" })
        .pipe(map((response) => this.mapStaticHtml(response, isTrusted)));
    }
  }

  private mapStaticHtml(htmlString: string, isTrusted: boolean): string {
    return isTrusted
      ? htmlString
      : this.domSanitizer.sanitize(SecurityContext.HTML, htmlString);
  }

  activeCustomers(consumerId: string){
    return this.http.get<CustomerSiteInfo[]>(`${this.urlBase}/Customer/${consumerId}/active-customers`);
  }

  upgradeConsumerPass(consumerPassId: string, changeToCustomerPassId: string) {
    return this.http.post(`${this.urlBase}/Dashboard/consumerpass/${consumerPassId}/upgradeto/${changeToCustomerPassId}`, {})
  }
}
